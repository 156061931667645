@media screen and (max-width: 1600px) {
    *, *::before, *::after {
        font-size: 13px;
    }
    app-header {
        width: calc(100% - 279px);
        left: 279px;
    }
    .right-layout {
        padding-left: 280px;
    }
    .stat-wrape {
        .stat-pad {
            padding: 1rem 1rem 1.15rem;
            p {
                padding-left: 65px;
                font-size: 13px;
            }
            h2 {
                padding-left: 65px;
                font-size: 20px;
                i {
                    left: 14px;
                    width: 50px;
                    height: 50px;
                    top: 16px;
                }
            }
        }
    }
    .input-wrape .mat-select {
        min-height: 43px;
    }
    .mat-expansion-panel {
        .mat-expansion-panel-body {
            padding: 16px 16px 16px;
        }
    }
    .page-title-box h1 {
        font-size: 13px !important;
    }
    .mat-tab-list {
        .mat-tab-label {
            .mat-tab-label-content {
                font-size: 14px;
            }
        }
    }
    .card-wrape {
        .card-head {
            padding: 8px 15px;
            .left-text {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 1370px) {
    *, *::before, *::after {
        font-size: 12px;
    }
    .mat-table .mat-header-cell, .mat-table .mat-sort-header-content {
        font-size: 13px;
    }
    .mat-table .mat-cell {
        font-size: 12px;
    }
    .mat-table .mat-header-cell, .mat-table .mat-cell {
        padding: 5px 12px !important;
    }
}

@media screen and (max-width: 1179px) {
    .right-layout {
        padding-left: 80px;
    }
    app-header {
        width: calc(100% - 79px);
        left: 79px;
    }
}

@media screen and (max-width: 767px) {
    app-main-container {
        padding: calc(70px + 0.75rem) 0.75rem 0 0.75rem;
    }
}