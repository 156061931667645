.display-flex {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex; 
}
.flexCenter {
    @extend .display-flex;
    justify-content: center;
    align-items: center;
}
@mixin border-radius($pixel) {
    border-radius: $pixel;
}
