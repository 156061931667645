@import "_sprite";
@import "_variable";
@import "_mixins";
@import "mt-color-theme-override";

*,
*::before,
*::after {
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    font-size: 14px;
    color: #6d6d6d;
    font-family: 'Poppins', sans-serif;
}

*::after,
*::before {
    font-size: 0;
    line-height: 1;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    background: #f3f3f9;
    font-family: 'Poppins', sans-serif;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
    font: 400 14px/20px 'Poppins', sans-serif;
}

.after-before {
    content: "";
    display: inline-block;
}

img {
    height: auto;
    max-width: 100%;
}

[placeholder] {
    text-overflow: ellipsis;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-overflow: ellipsis;
}

::-moz-placeholder {
    /* Firefox 19+ */
    text-overflow: ellipsis;
}

:-ms-input-placeholder {
    /* IE 10+ */
    text-overflow: ellipsis;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-overflow: ellipsis;
}

.mat-typography {
    p {
        font-size: 14px;
        color: #6d6d6d;
    }

    h1 {
        font-size: 20px;
        line-height: 1.5;
        color: #333333;
        font-weight: 600;
    }

    h2 {
        font-size: 18px;
        line-height: 1.5;
        color: #333333;
        font-weight: 600;
    }

    h3 {
        font-size: 16px;
        line-height: 1.5;
        color: #333333;
        font-weight: 600;
    }

    h4 {
        font-size: 20px;
        line-height: 1.5;
        color: #9ccb3d;
        font-weight: 600;
    }

    h5 {
        font-size: 18px;
        line-height: 1.5;
        color: #9ccb3d;
        font-weight: 600;
    }

    h6 {
        font-size: 16px;
        line-height: 1.5;
        color: #9ccb3d;
        font-weight: 600;
    }

    ul,
    li {
        list-style: none inside;
    }

    li {
        font-size: 1em;
        font-weight: 400;
        line-height: 1.43em;
        margin-bottom: 1.43em;
    }

    a {
        color: #9ccb3d;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    b {
        font-weight: 500;
        color: #333333;
    }
}

.p-0 {
    padding: 0;
}

section,
header,
footer {
    @extend .my-default;
}

.my-default {
    width: 100%;
    float: left;
}

.flexCenter {
    display: flex;
    justify-content: start;
    align-items: center;
}

.clear-both {
    clear: both;
    content: "";
}

// other UI style start 
.invidiual-btn-wrape {
    float: right;
    padding: 20px 0 20px;

    button {
        cursor: pointer;
        width: 86px;

        +button {
            margin-left: 10px;
        }
    }
}

// other UI style end

// mat error text height for file 
.mat-error-txt {
    top: 135px;
}

// spinner UI start
.mat-progress-spinner.mat-spinner {
    position: absolute;
    width: 50px !important;
    height: 50px !important;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -25px;
    z-index: 9;

    svg {
        width: 50px !important;
        height: 50px !important;
        position: relative;
    }

    +.progress-overlay {
        background: #00000033;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 9999;
    }
}

// spinner UI end
.button-controller {
    margin-top: 25px !important;
}

// accordian UI start 
form.view-mode {
    .head-controller-wrape {
        display: none;
    }
}

mat-accordion {
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: auto;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    overflow: hidden;

    .mat-expansion-panel-spacing {
        margin: 0;
        border-radius: 0 !important;
    }

}

.mat-expansion-panel {
    background: #ffffff;
    // margin-bottom: 15px !important;
    // border-radius: 4px !important;
    // margin-top: 0 !important;
    width: 100%;
    overflow: inherit !important;

    .mat-expansion-panel-body {
        padding: 16px 24px 16px;
    }

    mat-expansion-panel-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        height: 56px;
        padding: 0 20px;

        &::before {
            @extend .sprite-icon;
            position: absolute;
            right: 20px;
            top: 25px;
            background-position: -92px -3px;
            width: 10px;
            height: 7px;
        }

        span.mat-content {
            mat-panel-title {
                color: $primary;
                font-weight: 500;
                font-size: 17px;
                margin-top: 2px;
            }
        }

        .btn-wrapes {
            button {
                span.mat-button-wrapper {
                    font-size: 13px;
                }

                &.edit-btn-text {
                    span.mat-button-wrapper {
                        color: #ffffff;
                    }
                }
            }
        }

        &.mat-expanded {
            &::before {
                background-position: -106px -3px;
            }
        }

        mat-panel-title {
            color: #000000;
            font-size: 15px;
            font-weight: 400;
        }
    }

    .extention-part {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// accordian UI end
// modal UI start 
mat-dialog-container.mat-dialog-container {
    background: #ffffff;

    .mat-dialog-title {
        padding: 15px;
        box-shadow: 0 1px 20px rgb(56 65 74 / 15%);
    }

    .mat-dialog-content {
        max-height: calc(100vh - 180px);
        overflow: auto;
    }

    .mat-dialog-actions {
        padding: 10px;
    }
}

// modal UI end
// buttons general UI start 
button.btn,
button.mat-button {
    background: $primary;
    cursor: pointer;

    &.mat-raised-button {
        padding: 2px 16px;
    }

    span.mat-button-wrapper {
        color: #000000;
        font-size: 15px;
    }

    &.mat-basic {
        background: $white;
        color: $black;

        span.mat-button-wrapper {
            color: $black;
        }

        &:hover {
            background: $quaternary;
        }
    }

    &.mat-primary {
        background: $primary;
        color: #ffffff;

        span.mat-button-wrapper {
            color: #ffffff;
        }

        &:hover {
            opacity: 0.85;
        }
    }

    &.mat-secoundary {
        background: $seconadry;
        color: #ffffff;

        span.mat-button-wrapper {
            color: #ffffff;
        }

        &:hover {
            opacity: 0.75;
        }
    }

    &.mat-infos {
        background: $nonary;
        color: $black;

        span.mat-button-wrapper {
            color: $white;
        }

        &:hover {
            background: $octonary;

            span.mat-button-wrapper {
                color: $black;
            }
        }
    }

    &.mat-warn {
        background: $error;
        color: $white;

        span.mat-button-wrapper {
            color: $white;
        }

        &:hover {
            background: $denary;
        }
    }


}

// buttons general UI end

.choosen_file_div {
    position: relative;
    float: left;
}

.choosen_file_link {
    display: block;
    float: left;
    position: relative;
    top: 51px;
    padding: 0 0 0 30px;
}


// tabbing general UI start 
app-edit-participant {
    mat-tab-header {
        .mat-tab-labels {
            .mat-tab-label-content {
                flex-wrap: wrap;

                mat-icon {
                    @extend .sprite-icon;
                    display: block !important;
                    position: relative !important;
                    margin-bottom: 10px;

                    &.dash-icon {
                        background-position: -103px -278px;
                        width: 34px;
                        height: 24px;
                    }

                    &.pi-icon {
                        background-position: -2px -240px;
                        width: 45px;
                        height: 34px;
                    }

                    &.pmh-icon {
                        background-position: -53px -234px;
                        width: 23px;
                        height: 42px;
                    }

                    &.medication-icon {
                        background-position: -80px -238px;
                        width: 27px;
                        height: 34px;
                    }

                    &.surgeries-icon {
                        background-position: -109px -238px;
                        width: 38px;
                        height: 36px;
                    }

                    &.allergies-icon {
                        background-position: -151px -238px;
                        width: 32px;
                        height: 35px;
                    }

                    &.habit-icon {
                        background-position: -270px -241px;
                        width: 46px;
                        height: 34px;
                    }

                    &.cbd-icon {
                        background-position: 2px -278px;
                        width: 38px;
                        height: 34px;
                    }

                    &.med-report-icon {
                        background-position: -189px -239px;
                        width: 34px;
                        height: 35px;
                    }

                    &.fmh-icon {
                        background-position: -43px -278px;
                        width: 48px;
                        height: 34px;
                    }

                    &.sample-icon {
                        background-position: -229px -241px;
                        width: 38px;
                        height: 33px;
                    }

                    &.measurement-icon {
                        background-position: -333px -239px;
                        width: 26px;
                        height: 34px;
                    }

                    &.document-icon {
                        background-position: -378px -239px;
                        width: 26px;
                        height: 34px;
                    }

                    &.curation-icon {
                        background-position: -420px -238px;
                        width: 29px;
                        height: 35px;
                    }

                }

                span {
                    width: 100%;
                    font-size: 16px;
                    color: #444;
                }
            }

            .mat-tab-label.mat-tab-label-active {
                .mat-tab-label-content {
                    flex-wrap: wrap;

                    mat-icon {
                        &.dash-icon {
                            background-position: -103px -361px;
                        }

                        &.pi-icon {
                            background-position: -2px -321px;
                        }

                        &.pmh-icon {
                            background-position: -53px -315px;
                        }

                        &.medication-icon {
                            background-position: -80px -318px;
                        }

                        &.surgeries-icon {
                            background-position: -109px -319px;
                        }

                        &.allergies-icon {
                            background-position: -152px -319px;
                        }

                        &.habit-icon {
                            background-position: -270px -322px;
                        }

                        &.cbd-icon {
                            background-position: -2px -359px;
                        }

                        &.med-report-icon {
                            background-position: -189px -320px;
                        }

                        &.fmh-icon {
                            background-position: -44px -358px;
                        }

                        &.sample-icon {
                            background-position: -229px -321px;
                        }

                        &.measurement-icon {
                            background-position: -334px -322px;
                        }

                        &.document-icon {
                            background-position: -378px -322px;
                        }

                        &.curation-icon {
                            background-position: -420px -321px;
                        }
                    }

                    span {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

.mat-tab-list {
    .mat-tab-label {
        opacity: 1;

        .mat-tab-label-active {
            opacity: 0.5;

            .mat-tab-label-content {
                color: $primary;
            }
        }

        .mat-tab-label-content {
            font-size: 16px;
            color: $label-black;
        }
    }
}

.mat-tab-body-wrapper {
    .mat-tab-body {
        padding: 0;
        min-height: 200px;
    }

    .mat-tab-body-content {
        overflow: hidden;
    }
}

.tabbing-content-wrape {
    padding: 20px 10px 0;
    height: calc(100vh - 180px);
    overflow: auto;
}

// tabbing general UI end

*[class*="col-"] {
    margin-bottom: 20px;

    mat-expansion-panel {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

// swal style start 
button.swal2-confirm,
button.swal2-deny,
button.swal2-cancel {
    min-width: 75px;
    text-transform: uppercase;
    font-size: 13px;
    padding: 0.75rem 1.125rem;
    font-weight: 500;
    line-height: 13px;
    margin: .3125em;
}

.swal2-container {
    .swal2-popup {
        padding: 20px;
    }

    .swal2-image {
        margin: 0 auto;
    }

    .swal2-close {
        margin-right: -70px;
        height: 20px;
        border: 0;
        box-shadow: none !important;
    }
}

// swall style end

// form general UI start 
form.view-mode {
    .add-btn {
        pointer-events: none;
        opacity: 0.7;
    }

    .input-wrape {
        pointer-events: none;
        opacity: 0.75;

        mat-form-field.no-padding .mat-chip-list-wrapper .mat-chip+input {
            visibility: hidden !important;
        }

        .mat-form-field {
            .mat-form-field-wrapper {
                background: #ebebeb;
            }

            .mat-select-arrow-wrapper {
                opacity: 0.7;
            }
        }

        .control-pad .ng-select .ng-select-container {
            background: #ebebeb;
        }
    }

    .mat-table {
        pointer-events: none;
        opacity: 0.75;

        .mat-cell {
            .icons-wrape {
                // display: none;
            }
        }
    }
}

.control-pad .ng-select-disabled {
    .ng-select-container {
        background: #ebebeb;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.cdk-overlay-connected-position-bounding-box {
    .mat-option-text {
        padding-left: 0;

        span.name {
            padding-left: 20px;
        }
    }
}

.contact-no-pad {
    mat-select-trigger {

        //padding-left: 25px;
        .img {
            position: absolute;
            left: 0;
            top: 5px;

            img {
                height: 15px;
            }
        }
    }
}

.input-wrape {
    &.pi-contact {
        .number.un-verified-thing {
            position: relative;

            .side-info {
                position: absolute;
                right: 10px;
                top: 10px;

                .info-icon {
                    background: none;
                    color: $error;

                    &:before {
                        @extend .after-before, .sprite-icon;
                        background-position: -273px -79px;
                        width: 17px;
                        height: 17px;
                        position: relative;
                        top: 4px;
                        margin-right: 4px;
                    }
                }

                .info-section {
                    width: auto;
                    background: #000000;
                    padding: 15px 15px 15px 15px;
                    border-radius: 5px;
                    display: none;
                    position: absolute;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                    right: -10px;
                    line-height: 1.5;
                    z-index: 4;
                    text-align: left;
                    top: 140%;
                    min-width: 200px;

                    &:after {
                        width: 10px;
                        height: 10px;
                        top: -5px;
                        right: 18px;
                        transform: rotate(45deg);
                        background: #000000;
                        content: "";
                        display: inline-block;
                        position: absolute;
                    }
                }

                &:hover {
                    .info-section {
                        display: block;
                    }
                }
            }
        }

        .number.verified-thing {
            position: relative;

            .side-info {
                position: absolute;
                right: 10px;
                top: 10px;

                .info-icon {
                    background: none;
                    color: $primary;

                    &:before {
                        @extend .after-before, .sprite-icon;
                        background-position: -294px -79px;
                        width: 17px;
                        height: 17px;
                        position: relative;
                        top: 4px;
                        margin-right: 4px;
                    }
                }
                .close-icon {
                    background: none;
                    color: $primary;

                    &:before {
                        @extend .after-before, .sprite-icon;
                        background-position: -273px -79px;
                        width: 17px;
                        height: 17px;
                        position: relative;
                        top: 4px;
                        margin-right: 4px;
                    }
                }

                .info-section {
                    width: auto;
                    background: #000000;
                    padding: 15px 15px 15px 15px;
                    border-radius: 5px;
                    display: none;
                    position: absolute;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                    right: -10px;
                    line-height: 1.5;
                    z-index: 4;
                    text-align: left;
                    top: 140%;
                    min-width: 200px;

                    &:after {
                        width: 10px;
                        height: 10px;
                        top: -5px;
                        right: 18px;
                        transform: rotate(45deg);
                        background: #000000;
                        content: "";
                        display: inline-block;
                        position: absolute;
                    }
                }

                &:hover {
                    .info-section {
                        display: block;
                    }
                }
            }
        }
    }

    .control-pad {
        position: relative;
        width: 100%;
        padding-bottom: 18px;
        color: #222222;
        margin-top: 0;
        min-height: 43px;

        .ng-select {

            // padding: 10px 0;
            // border: 1px solid rgba(0, 0, 0, 0.2);
            // border-radius: 4px;
            .ng-has-value .ng-placeholder {
                display: none;
            }

            .ng-value {
                float: left;
            }

            &.ng-select-single .ng-dropdown-panel {
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                overflow: hidden;
                max-width: 100%;
                z-index: 998;
                margin: 0;
            }

            .ng-select-container {
                // padding: 3px 10px 0 !important;
                min-height: 43px;
            }

            .ng-value-container {
                .ng-input {
                    // position: absolute;
                    left: 0;
                    width: 100%;
                    padding-left: 6px;
                    padding-right: 40px;
                    padding-top: 4px;
                }

                .ng-placeholder {
                    color: #8f9bb3;
                    vertical-align: top;
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    top: 10px;
                }
            }

            .ng-dropdown-panel {
                background-color: #fff;
                border: 1px solid #ccc;
                border-top-color: rgb(204, 204, 204);
                -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .06);
                box-shadow: 0 1px 0 rgba(0, 0, 0, .06);
                left: 0;

                .ng-select-top {
                    overflow: hidden;
                    border-top-color: #e6e6e6;
                    border-radius: 5px 5px 0 0;
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                }

                .ng-select-bottom {
                    top: 100%;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    border-top-color: #e6e6e6;
                    margin-top: -1px;
                }
            }

            .ng-dropdown-panel-items .ng-option {
                cursor: pointer;
                display: block;
                white-space: unset;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 13px;
                text-transform: initial;
                background-color: #fff;
                color: rgba(0, 0, 0, .87);
                padding: 8px 10px;

                &.ng-option-selected {
                    color: #333;
                    background-color: #ebf5ff;
                }
            }

            .ng-dropdown-panel .ng-dropdown-panel-items {
                display: block;
                height: auto;
                box-sizing: border-box;
                max-height: 240px;
                overflow-y: overlay;
            }
        }

        .ng-clear-wrapper {
            width: 9px;

            .ng-clear {
                height: 9px;
                color: $error;
            }
        }

        .ng-dropdown-panel {
            background: $white;
        }
    }
}

.mat-option-text {
    padding-left: 19px;

    .img {
        position: absolute;
        left: 8px;
        top: 3px;

        img {
            height: 15px;
        }
    }

    .code {
        position: absolute;
        right: 8px;
        top: 0px;
        font-size: 11px;
        font-weight: 500;
        color: $black;
    }
}

.mat-checkbox-frame {
    border: 2px solid $form-border-color-dark;
}

.contact-no-pad {
    display: flex;

    .input-wrape {
        &.code {
            max-width: 160px;
        }

        &.number {
            padding-left: 20px;
        }
    }
}

.timepicker__header {
    background: $seconadry !important;

    .timepicker-dial__time {
        span {
            color: #ffffff !important;
            padding: 0 5px;
            font-size: 40px;
        }
    }
}

.clock-face__number span.active {
    background: $seconadry !important;
    color: #ffffff !important;
}

.clock-face__clock-hand {
    background: $seconadry !important;
}

.input-wrape {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .mat-form-field {
        background: #ffffff;
    }

    &.half-wrape {
        .container-fluid {
            padding: 0;
        }

        .time-wrape {
            .default-time-example {
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 4px;
                padding: 11px 12px;

                span.deafult-text {
                    margin-right: 10px;
                    opacity: 0.7;
                }
            }
        }
    }

    .img-wrape {
        line-height: 1;

        img {
            line-height: 1;
        }

        .delete-icon {
            background: $denary;
            width: 24px;
            height: 24px;
            display: inline-block;
            position: absolute;
            display: block;
            right: 10px;
            pointer-events: painted;
            bottom: 10px;
            border: 3px;

            &::before {
                @extend .sprite-icon;
                background-position: -235px -3px;
                width: 16px;
                height: 16px;
                margin-top: 5px;
                margin-left: 5px;
            }
        }
        .download-icon {
            background: url(../images/download_icon.jpg) no-repeat;
            width: 24px;
            height: 24px;
            display: inline-block;
            position: absolute;
            display: block;
            pointer-events: painted;
            bottom: 10px;
            border: 3px;
        }
    }

    mat-form-field.no-padding {
        input {
            padding: 0;
        }

        .mat-chip-list-wrapper {
            input {
                margin: 0;
                padding: 3px 0 0;
            }
        }
    }

    &.tree {
        &.new-upload {
            i.ruler {
                &::after {
                    height: calc(100% - 59px);
                }
            }
        }

        &.one {
            &.heighed {
                i.ruler {
                    &::after {
                        height: calc(100% - 44px);
                    }
                }
            }
        }

        i.ruler {
            &::after {
                @extend .after-before;
                width: 1px;
                background: rgba(0, 0, 0, 0.2);
                height: calc(100% - 17px);
                position: absolute;
                left: 7px;
                top: -18px;
            }

            &::before {
                @extend .after-before;
                width: 13px;
                background: rgba(0, 0, 0, 0.2);
                height: 1px;
                position: absolute;
                left: 7px;
                top: 53px;
            }
        }

        i.ruler.parents_blood_relation {
            &::after {
                @extend .after-before;
                width: 1px;
                background: rgba(0, 0, 0, 0.2);
                height: 116%;
                position: absolute;
                left: -13px;
                top: -44px;
            }

            &::before {
                @extend .after-before;
                width: 13px;
                background: rgba(0, 0, 0, 0.2);
                height: 1px;
                position: absolute;
                left: -13px;
                top: 26px;
            }
        }

        .upload-wrape.side-info+i.ruler {
            &::after {
                height: calc(100% - 10px);
                top: -20px;
            }
        }

        .img-wrape+i.ruler {
            &::after {
                height: calc(100% - 71px);
            }
        }

        &.extend.no-label {
            i.ruler {
                &::after {
                    top: -91px;
                    height: calc(100% + 0px);
                }

                &::before {
                    top: 28px;
                }
            }

            .icon-wrape-preview.thumb-upload {
                +i.ruler {
                    &::after {
                        top: -97px;
                    }
                }
            }
        }

        &.one {
            padding-left: 20px;

            mat-radio-group {
                +i.ruler {
                    &:before {
                        top: 37px;
                    }

                    &::after {
                        height: calc(100% - 9px);
                    }
                }
            }
        }

        &.two {
            padding-left: 40px;

            i.ruler {
                &::after {
                    height: calc(100% + 0px);
                    top: -34px;
                }

                &::before {
                    width: 33px;
                }
            }

            .upload-wrape.side-info+i.ruler {
                &::after {
                    height: calc(100% + 10px);
                    top: -20px;
                }
            }
        }
    }

    &.has-error {

        .mat-input-element::placeholder,
        .mat-select-placeholder {
            color: #f44336;
        }

        .mat-form-field-wrapper,
        .ng-select-container {
            border-color: #f44336;
        }

        .mat-error {
            position: absolute;
            left: 0;
            bottom: 0px;
            font-size: 12px;
        }

        &.rc-wrape {
            mat-label {
                color: #f44336;
            }
        }

        &.half-wrape {
            .time-wrape {
                .default-time-example {
                    border-color: #f44336;
                }
            }
        }
    }

    &.calendar-wrape {
        .mat-form-field-appearance-outline .mat-form-field-suffix {
            top: -10px;
        }
    }

    .half-controls {
        display: flex;
        width: 100%;

        .half {
            width: 50%;
            padding-right: 10px;

            +.half {
                padding-left: 10px;
                padding-right: 0;
            }
        }
    }

    .three-controls {
        display: flex;
        width: 100%;

        .threes {
            width: 33.33%;

            +.threes {
                padding-left: 10px;
            }
        }
    }

    textarea.mat-input-element {
        padding: 10px 0;
        height: 60px;
        margin: 5px 0;
    }

    .mat-radio-group {
        width: 100%;
        padding-left: 2px;

        .mat-radio-button {
            width: auto;
            margin-right: 40px;

            span.mat-radio-label-content {
                padding-left: 5px;
            }
        }
    }

    .mat-chip.mat-standard-chip {
        .mat-chip-remove {
            color: $black;
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            width: 27px;
            height: 27px;
            text-align: center;
            line-height: 1.24;
        }
    }

    .mat-select {
        display: flex;
        padding: 14px 0 12px;
        min-height: 43px;

        .mat-select-value {
            overflow: inherit;
        }

        &.mat-select-multiple {
            padding: 0;
        }

        span.mat-select-value-text {
            span {
                color: #222222;

                &.c-code {
                    color: $primary;
                    font-weight: 500;
                }
            }
        }
    }

    .upload-wrape {
        position: relative;
        width: 118px;

        .mat-error {
            bottom: 0;
        }

        &.side-info {
            padding-right: 30px;

            .info-icon {
                position: absolute;
                display: block;
                right: 2px;
                pointer-events: painted;
                top: 10px;
                border: 0;
                @extend .sprite-icon;
                background-position: -67px -28px;
                width: 21px;
                height: 22px;

                &:hover+.info-section {
                    display: block;
                }
            }

            .info-section {
                width: auto;
                background: #000000;
                padding: 15px 15px 15px 15px;
                border-radius: 5px;
                display: none;
                position: absolute;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                right: -10px;
                line-height: 1.5;
                z-index: 4;
                text-align: left;
                top: 80%;
                min-width: 200px;

                &:after {
                    width: 10px;
                    height: 10px;
                    top: -5px;
                    right: 18px;
                    transform: rotate(45deg);
                    background: #000000;
                    content: "";
                    display: inline-block;
                    position: absolute;
                }

                .close-btn {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }

        button#uploadButton {
            position: relative;
            left: 0;
            top: 0;
            background: $seconadry;
            color: $white;
            font-size: 15px;
            font-weight: 500;
            padding: 2px 15px;
            opacity: 1;
            pointer-events: none;
            z-index: 3;
            margin-bottom: 2px;
            cursor: pointer;
            display: flex;
            margin-bottom: 18px;

            span {
                cursor: pointer;
            }

            &:hover {
                opacity: 0.75;
            }
        }

        input#fileInput {
            position: absolute;
            opacity: 0;
            width: 85px;
            height: 41px;
            cursor: pointer;
            z-index: 1;
        }
    }

    mat-label {
        color: $label-black;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
        width: 100%;
        display: block;

        span {
            color: $primary;
        }

        i {
            font-style: normal;
            color: $error;
        }
    }

    .mat-form-field {
        width: 100%;
        margin-bottom: 18px;

        &.disabled {
            .mat-form-field-wrapper {
                background: $diabled-input;
                pointer-events: none;
            }
        }
    }

    .form-control {
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 4px 10px 4px 8px;
        font-size: 14px;
    }

    input.form-control[type="text"] {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .mat-checkbox-inner-container {
        width: 19px;
        height: 19px;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-radio-outer-circle {
        border-color: $form-border-color-dark;
    }

    .input-wrape .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
    }

    .mat-radio-button,
    .mat-checkbox {
        margin: 0 0 18px;
        width: 100%;
    }

    input.mat-input-element {
        font-size: 14px;
        color: $black;
        padding: 10px 0 9px;
        margin-top: 0;
        min-height: 43px;
    }

    .mat-input-element::placeholder,
    .mat-select-placeholder {
        color: $black;
        opacity: 0.5;
        font-size: 15px;
    }

    .mat-form-field-appearance-outline {
        .mat-form-field-outline {
            div {
                display: none;
            }
        }

        .mat-select-arrow-wrapper {
            transform: translateY(0%);
        }

        .mat-form-field-outline {
            top: 0 !important;
        }

        .mat-form-field-flex {
            margin-top: 0;
        }

        .mat-form-field-infix {
            margin-top: 0;
            border-top: 0;
            width: 100%;
        }

        &:hover,
        &.mat-focused {

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                border-width: 1px;
            }
        }

        mat-datepicker-toggle {
            button {
                width: 16px !important;
                height: 16px !important;
                position: relative;
                top: 2px;

                .mat-icon {
                    height: 17px;
                }
            }
        }
    }
}

mat-option {
    .mat-pseudo-checkbox {
        border: 2px solid $grey-black;
        width: 19px;
        height: 19px;

        &.mat-pseudo-checkbox-checked {
            background: $seconadry;

            &::after {
                color: $white;
                border-left-width: 2px;
                border-bottom-width: 2px;
                top: 3px;
                left: 3px;
                height: 5px;
            }
        }
    }
}

// form general UI end 

// table general UI start 
.table-wrape {
    overflow-x: auto;
}

.form-area {
    .form-pad {
        &.disableTableform {
            pointer-events: none;
        }
    }
}

.mat-table {
    background: #ffffff;
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    color: #212121;
    box-shadow: none;

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 0;
    }

    .inner-bg-gray {
        border-bottom: 1px solid #e0e0e0;
    }

    .mat-header-row {
        align-items: stretch;
    }

    .example-element-row {
        background: #ffffff;
    }

    tr.example-detail-row {
        height: 0;

        td {
            padding: 0 !important;
        }
    }

    .example-element-detail {
        overflow: hidden;
        display: flex;
    }

    .mat-no-data-row {
        padding: 15px;
        text-align: center;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-left: 0;
        font-size: 16px;
        color: $denary;
    }

    .mat-row {
        border-bottom: 1px solid #e0e0e0;
        align-items: stretch;

        &:nth-of-type(2n+1) {
            background: #f9f9f9;
        }
    }

    .mat-cell {
        font-weight: 400;
        color: #333;
        font-size: 14px;
        margin-bottom: 0;

        .icons-wrape {
            @extend .flexCenter;
            width: 100%;

            a {
                @extend .sprite-icon;
                position: relative;
                border-radius: 3px;
                width: 26px;
                height: 26px;
                transition: all 0.25s ease-in-out 0.25s;

                &::before {
                    width: 15px;
                    height: 15px;
                    position: absolute;
                }

                &:hover {
                    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
                    transition: all 0.25s ease-in-out 0.25s;
                }
            }

            button {
                &:hover {
                    opacity: 0.75;
                }
            }

            .unapproved-icon {
                background: $seconadry;

                &::before {
                    top: 6px;
                    left: 5px;
                }
            }

            .green-bg-btn {
                background: $primary;
            }

            .blue-bg-btn {
                background: $seconadry;
            }

            .sky-bg-btn {
                background: $septenary;
            }

            .danger-bg-btn {
                background: $error;
            }

            .edit-icon {
                background: $seconadry;

                &::before {
                    background-position: -196px -4px;
                    top: 6px;
                    left: 5px;
                }
            }

            .view-icon {
                background: $seconadry;
            }

            .hide-icon {
                background: $seconadry;
                &::before {
                    top: 8px;
                    left: 6px;
                }
            }

            .delete-icon {
                background: $denary;

                &::before {
                    background-position: -235px -3px;
                    top: 5px;
                    left: 6px;
                }
            }

            .view-icon {
                &::before {
                    top: 8px;
                    left: 6px;
                }
            }

            .link-icon {
                &::before {
                    top: 5px;
                    left: 5px;
                    width: 17px;
                    height: 17px;
                }
            }
        }
    }

    .mat-header-cell,
    .mat-cell {
        border-right: 1px solid #e0e0e0;
        padding: 8px 15px !important;

        &.mat-column-action {
            // text-align: center;
            // justify-content: center;
        }

        img {
            max-width: 40px;
        }
    }

    .mat-header-row {
        border-bottom: 2px solid #405189;
        min-height: 48px;
    }

    .mat-header-cell {
        cursor: pointer;
    }

    .mat-header-cell,
    .mat-sort-header-content {
        font-weight: 500;
        color: #212121;
        font-size: 15px;
        text-align: left;
    }
}

.mat-select-panel {
    background: #ffffff;
    border-radius: 4px !important;

    mat-option {
        background: #f9f9f9;

        &.active {
            background: #ffffff;
        }

        &:hover {
            background: #ffffff;
        }
    }
}

.mat-raised-button.mat-button-disabled.mat-button-disabled {
    background: #162c55;
    opacity: 0.5;
    pointer-events: none;
}

.mat-paginator {
    background: #ffffff;
    color: #212121;

    .mat-select {
        background: #ffffff;
        padding: 5px 0;
        box-shadow: none;
    }

    button.mat-button-disabled {
        opacity: 0.5;
    }

    .mat-paginator-page-size-select {
        width: 65px;

        .mat-select-value {
            padding-left: 10px;
        }

        .mat-select-arrow-wrapper {
            position: absolute;
            right: 2px;
            top: -2px;
        }
    }

    .mat-paginator-container {
        min-height: inherit;

        .mat-form-field-wrapper {
            padding-bottom: 0;

            .mat-form-field-infix {
                background: #f0f0f0;
                border: 1px solid #e0e0e0;
            }
        }

        .mat-select-arrow {
            color: rgba(0, 0, 0, 0.5);
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid;
            margin: 0 4px;
        }

        .mat-form-field-underline {
            display: none;
        }
    }
}

.table-search-wrape {
    position: relative;
    padding-right: 32px;

    span.icon {
        position: absolute;
        z-index: 2;
        display: block;
        pointer-events: none;
        @extend .sprite-icon;
        background-position: -133px -2px;
        width: 15px;
        height: 16px;
        top: 11px;
        left: 11px;
    }

    .form-control {
        padding: 8px 10px 8px 30px;
        font-weight: 500;
        border: 1px solid $form-border-color;
        border-radius: 4px;
    }

    &.side-info {
        padding-right: 30px;

        .info-icon {
            position: absolute;
            right: 20px;
            display: block;
            right: 0;
            pointer-events: painted;
            top: 7px;
            border: 0;
            @extend .sprite-icon;
            background-position: -67px -28px;
            width: 21px;
            height: 22px;

            &:hover+.info-section {
                display: block;
            }
        }

        .info-section {
            width: auto;
            background: #000000;
            padding: 15px 15px 15px 15px;
            border-radius: 5px;
            display: none;
            position: absolute;
            color: #fff;
            font-size: 12px;
            font-weight: 400;
            right: -10px;
            line-height: 1.5;
            z-index: 2;
            text-align: left;

            &:after {
                width: 10px;
                height: 10px;
                top: -5px;
                right: 18px;
                transform: rotate(45deg);
                background: #000000;
                content: "";
                display: inline-block;
                position: absolute;
            }

            .close-btn {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
    }

    +button.btn {
        margin-left: 15px;
    }
}

// table general UI end 

.custom-icon-tooltip {
    &.yellow {
        padding-right: 30px;

        .info-icon {
            position: absolute;
            right: 8px;
            display: block;
            pointer-events: painted;
            top: 9px;
            border: 0;
            @extend .sprite-icon;
            background-position: -114px -28px;
            width: 21px;
            height: 22px;

            &:hover+.info-section {
                display: block;
            }
        }

        .info-section {
            width: auto;
            background: #ffffff;
            padding: 15px 15px 15px 15px;
            border-radius: 5px;
            display: none;
            position: absolute;
            color: #000000;
            font-size: 12px;
            font-weight: 400;
            right: -10px;
            line-height: 1.5;
            z-index: 2;
            text-align: left;
            bottom: -27px;
            border: 1px solid #f4bd3d;

            &:after {
                width: 10px;
                height: 10px;
                top: -5px;
                right: 15px;
                transform: rotate(45deg);
                background: #ffffff;
                content: "";
                display: inline-block;
                position: absolute;
                border-left: 1px solid #f4bd3d;
                border-top: 1px solid #f4bd3d;
            }

            .close-btn {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
    }
}

.total-count-piechart {
    font-size: 23px;
    color: #222b45;
    font-weight: 500;
    position: absolute;
    right: 23px;
    top: 23px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;

    span {
        font-size: 15px;
        color: #8f9bb3;
        display: block;
    }
}

@media screen and (max-width: 1600px) {

    button.btn,
    button.mat-button,
    .mat-raised-button {
        line-height: 28px;

        &.mat-raised-button {
            padding: 5px 14px;
        }

        span.mat-button-wrapper {
            font-size: 13px;
        }
    }

    .mat-table {

        .mat-header-cell,
        .mat-sort-header-content {
            font-size: 13px;
            padding: 5px 10px !important;
        }
    }
}