html,
body {
    $font-size-base: 1rem;
    @media screen and (min-width: md) and (max-width: 1440px) {
        font-size: 1em;
    }
    @media screen and (min-width: 1440px) and (max-width: xl) {
        font-size: 1.25em;
    }

    @media only screen and (min-width: xxl) {
        font-size: 1.375em;
    }
}
/* Media queries */

/* Colors */
$white: #ffffff;
$primary: #9ccb3d;
$seconadry: #162c55;
$tertiary: #75ab28;
$quaternary: #ebeeff;
$quinary: #e83e8c !default;
$senary: #e0e0e0;
$septenary: #73c0de;
$octonary: #ebf5ff;
$nonary: #b8dbff;
$denary: #ee4141;
$duodenary: #f6f9fe;
$black: #222222;
$bgcolor: #f7f7f7;
$grey-black: #596977;
$theme-colors: () !default;
$new-gray: #c4c4c4;
$error: #ed1717;
$label-black: #444444;
$form-border-color: rgba(0, 0, 0, 0.20);
$form-border-color-dark: rgba(0, 0, 0, 0.40);
$side-purple-color: #405189;
$diabled-input: #ebebeb;
$calendar-button: #999999;
// $theme-colors: map-merge(
//     (
//         "bg-green": $primary,
//         "dark-blue": $seconadry,
//         "light-blue": $septenary,
//         "light-black": $grey-black,
//     ),
//     $theme-colors
// );
/*btn modifiers*/

/*For spacer (padding & margin)*/
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        6: (
            $spacer * 7,
        ),
        7: (
            $spacer * 4.4,
        ),
        8: (
            $spacer * 5,
        ),
        9: (
            $spacer * 5.6,
        ),
        10: (
            $spacer * 6,
        ),
        11: (
            $spacer * 8,
        ),
        12: (
            $spacer * 9,
        ),
    ),
    $spacers
);
/*ends spacer here*/
