.sprite-icon {
    content: "";
    display: inline-block;
    background: url("../images/sprite.svg") no-repeat 0 0;
}
.hamburg-icon {
    @extend .sprite-icon;
    background-position: -2px -2px;
    width: 18px;
    height: 18px;
    top: 12px;
    left: 0px;
}
.sidebar-slide-in .hamburg-icon {
    background-position: -38px -42px;
    top: 10px;
}
.filter-btn {
    i {
        @extend .sprite-icon;
        background-position: -238px -21px;
    }
}
mat-icon.calendar-icon {
    @extend .sprite-icon;
    background-position: -180px -20px;
    width: 16px;
	height: 16px;
}
button.back-to-top-btn {
    &:after {
        @extend .sprite-icon;
        background-position: -411px -4px;
        width: 12px;
        height: 16px;
    }
}
// individual icon start 
a.indi-icon, button.indi-icon {
    float: right;
    background: #1a3159;
    border-radius: 4px;
    position: relative;
    width: 35px;
    height: 35px;
    &.disable {
        background: #596977 !important;
        pointer-events: none;
    }
    &:hover {
        opacity: 0.85;
    }
    &::before {
        position: absolute;        
        @extend .sprite-icon;
    }
    &.add-btns {
        &::before {
            background-position: -342px -25px;
            width: 13px;
            height: 13px;
            left: 11px;
            top: 11px;
        }
    }
    &.remove-btns {
        background: #ed1717;
        &::before {
            background-position: -235px -3px;
            width: 13px;
            height: 15px;
            left: 10px;
            top: 9px;
        }
    }
    &.lab-icons {
        &::before {
            background-position: -392px -4px;
            width: 16px;
            height: 18px;
            left: 10px;
            top: 9px;
        }
    }
    &.pmr-icon {
        &::before {
            background-position: -355px -4px;
            width: 14px;
            height: 18px;
            left: 11px;
            top: 8px;
        }
    }
    &.report-icon {
        &::before {
            background-position: -371px -4px;
            width: 17px;
            height: 19px;
            left: 9px;
            top: 7px;
        }
    }
    &.edit-icon {
        &::before {
            background-position: -196px -3px;
            width: 15px;
            height: 14px;
            left: 10px;
            top: 10px;
        }
    }
    &.save-icon {
        background: #162c55;
        &::before {
            background-position: -234px -57px;
            width: 13px;
            height: 15px;
            left: 11px;
            top: 10px;
        }
    }
    &.cancel-icon {
        background: #ed1717;
        &::before {
            background-position: -152px -3px;
            width: 10px;
            height: 11px;
            left: 13px;
            top: 12px;
        }
    }
    &.sync-icon {
        background: #162c55;
        &::before {
            background-position: -301px -23px;
            width: 16px;
            height: 15px;
            left: 10px;
            top: 10px;
        }
    }
}
.border-icon-btn {
    i.info-icon {
        @extend .sprite-icon;
        background-position: -381px -47px;
        width: 20px;
	    height: 20px;
        display: inline-block;
    }
}
// individual icon end

.icon-wrape-preview {
    span.icon {
        @extend .sprite-icon;
    }
}
// table action button start here
.icons-wrape {
    display: flex;
    justify-content: space-between;
    .theme-icon {
        width: 16px;
        height: 16px;
        margin: 0 2px;
        position: relative;
        &:before {
            @extend .sprite-icon;
        }
    }
    .edit-icon {
        &::before {
            background-position: -196px -4px;
        }
    }
    .view-icon {
        &::before {
            background-position: -234px -44px;
            width: 15px !important;
            height: 9px !important;
        }
    }
    .view-icon {
        &::before {
            background-position: -234px -44px;
            width: 15px !important;
            height: 9px !important;
        }
    }
    .hide-icon {
        &::before {
            background-position: -216px -43px;
            width: 14px !important;
            height: 11px !important;
        }
    }
    .link-icon {
        &::before {
            background-position: -321px -22px;
        }
    }
    .unapproved-icon {
        &::before {
            background-position: -269px -2px;
        }
    }
    .sync-icon {
        background: #162c55 !important;
        &::before {
            background-position: -301px -23px;
            width: 16px !important;
            height: 15px !important;
            left: 5px;
            top: 5px;
        }
    }
}
// table action button start end

a.back-btn {
    &::before {        
        @extend .sprite-icon;
        content: "";
        display: inline-block;
        position: absolute;        
        background-position: -38px -26px;
        width: 19px;
        height: 13px;
        left: 21px;
        top: 22px;
    }
}

// state box icons start here
i.theme-icon {
    border-radius: 4px;
    &::before {        
        @extend .sprite-icon;
        content: "";
        display: inline-block;
        position: absolute;
    }
    &.participant-enroll {
        &::before {
            background-position: -2px -105px;
            width: 43px;
            height: 35px;
            left: 8px;
            top: 12px;
        }
    }
    &.camp-held {
        &::before {
            background-position: -53px -105px;
            width: 35px;
            height: 37px;
            left: 13px;
            top: 11px;
        }
    }
    &.couselor {
        &::before {
            background-position: -97px -106px;
            width: 27px;
            height: 35px;
            left: 15px;
            top: 12px;
        }
    }
    &.participants {
        &::before {
            background-position: -257px -42px;
            width: 28px;
            height: 33px;
            left: 15px;
            top: 12px;
        }
    }
    &.males {
        &::before {
            background-position: -294px -45px;
            width: 27px;
            height: 26px;
            left: 17px;
    top: 19px;
        }
    }
    &.females {
        &::before {
            background-position: -325px -41px;
            width: 22px;
            height: 32px;
            left: 19px;
    top: 13px;
        }
    }
    &.others {
        &::before {
            background-position: -353px -43px;
            width: 22px;
            height: 32px;
            left: 18px;
            top: 14px;
        }
    }
    &.audio {
        &::before {
            background-position: -98px -155px;
            width: 24px;
            height: 25px;
            left: 18px;
            top: 18px;
        }
    }
    &.image {
        &::before {
            background-position: -203px -158px;
            width: 28px;
            height: 22px;
            left: 17px;
            top: 19px;
        }
    }
    &.disease {
        &::before {
            background-position: -126px -156px;
            width: 28px;
            height: 27px;
            left: 18px;
            top: 17px;
        }
    }
}
// state box icons start here

// sidebar icons start here 
.parent-menu {

}
a, .new-menu-wrapes {
    .mat-content {
        overflow: inherit;
    }
    .icons {
        @extend .sprite-icon;
    }
    i {
        &.icons {
            position: absolute;
            left: -30px;
            &.dashboard {            
                background-position: -2px -61px;
                width: 20px;
                height: 14px;
            }
            &.utility {
                background-position: -209px -58px;
                    width: 21px;
                    height: 16px;
            }
            &.manage-dictionary {
                background-position: -121px -60px;
                width: 15px;
                height: 16px;
            }
            &.d-management {
                background-position: -47px -61px;
                width: 16px;
                height: 18px;
            }
            &.super-administration {
                background-position: -47px -61px;
                width: 16px;
                height: 18px;
            }
            &.super-administration {
                background-position: -68px -61px;
                width: 21px;
                height: 18px;
            }
            &.project {
                background-position: -94px -61px;
                width: 22px;
                height: 18px;
            }
            &.data-curation {
                background-position: -121px -60px;
                width: 16px;
                height: 18px;
            }
        }
    }
}

// for new menu
a, .mat-tree {
    .icons {
        @extend .sprite-icon;
    }
    i {
        &.icons {
            position: unset;
            left: -30px;
            margin-left: 15px;
            margin-right: 15px;
            cursor: pointer;
            &.dashboard {            
                background-position: -2px -61px;
                width: 20px;
                height: 14px;
            }
            &.utility {
                background-position: -209px -58px;
                    width: 21px;
                    height: 16px;
            }
            &.manage-dictionary {
                background-position: -121px -60px;
                width: 15px;
                height: 16px;
            }
            &.d-management {
                background-position: -47px -61px;
                width: 16px;
                height: 18px;
            }
            &.super-administration {
                background-position: -47px -61px;
                width: 16px;
                height: 18px;
            }
            &.super-administration {
                background-position: -68px -61px;
                width: 21px;
                height: 18px;
            }
            &.project {
                background-position: -94px -61px;
                width: 22px;
                height: 18px;
            }
            &.data-curation {
                background-position: -121px -60px;
                width: 16px;
                height: 18px;
            }
        }
    }
}
// sidebar icons end here

// accordion icons start here
.edit-btn-text {
    &::after {
        @extend .sprite-icon;
        background-position: -233px -80px;
        width: 15px;
        height: 15px;
        display: inline-block;
        position: absolute;
        left: 11px;
        top: 8px;
    }
}
// accordion icons end here


@media screen and (max-width: 1600px) {
    i.theme-icon {
        &.participant-enroll::before {
            background-position: -2px -147px;
            width: 33px;
	        height: 27px;
        }
        &.camp-held::before {
            background-position: -41px -148px;
            width: 26px;
	        height: 27px;
        }
        &.couselor::before {
            background-position: -73px -148px;
            width: 21px;
	        height: 27px;
        }
        &.audio::before {
            left: 14px;
            top: 13px;
        }
        &.image::before {
            left: 12px;
            top: 15px;
        }
        &.disease::before {
            left: 12px;
            top: 12px;
        }
    }
}