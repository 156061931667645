@import "default-theme";
@import "~@ng-select/ng-select/themes/default.theme.css";
/* ======== SIDE MENU STYLE START ======== */
.page-construction {
    display: table;
    margin: 0 auto 20px;
    text-align: center;
    img {
        max-width: 450px;
        box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.15);
        border-radius: 30px;
        margin-bottom: 20px;
        background: #fff;
        padding: 20px;
    }
    h1 {
        color: #9ccb3d;
        font-size: 24px;
    }
    p {
        font-size: 16px;
    }
}
.page-title-box {
    padding: 10px 24px;
    margin: -28px -1rem 1.5rem -1rem;
    box-shadow: 0 1px 2px rgba(56,65,74,.15);
    background: #fff;
    overflow: hidden;
    h1 {float: left; font-weight: 700; font-size: 15px !important; text-transform: uppercase; margin-bottom: 0;}
    ul.bradcrumb {
        float: right;
        margin-top: 2px;
        li {
            float: right;
            font-weight: 500;
            position: relative;
            padding-left: 30px;
            margin-bottom: 0;
            &::before {
                @extend .after-before, .sprite-icon;
                background-position: -121px -2px;
                position: absolute;
                left: 12px;
                top: 5px;
                width: 5px;
                height: 8px;
            }
            &:last-child {
                padding-left: 0;
                &::before {
                    display: none;
                }
            }
            a {
                font-weight: 500;
                &:hover {text-decoration: none; color: $tertiary;}
                &::before {
                    @extend .after-before ;
                }
                &.dashboard {
                    padding-left: 20px;
                    position: relative;
                    &:before {
                        background: url(../images/sprite.svg) no-repeat -115px -12px;
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 15px;
                        height: 13px;
                    }
                }
            }
        }
    }
}
.m-0 {margin: 0 !important;}
.m-b-0 { margin-bottom: 0 !important;}
.m-t-0 { margin-top: 0 !important;}

.m-0 {margin: 0 !important;}
.m-b-5 { margin-bottom: 5px !important;}
.m-b-10 { margin-bottom: 10px !important;}
.m-b-15 { margin-bottom: 15px !important;}
.m-t-10 { margin-top: 10px !important;}
.m-t-15 { margin-top: 15px !important;}
.m-t-20 { margin-top: 20px !important;}

.p-0 {padding: 0 !important;}
.p-t-0 { padding-top: 0 !important;}
.p-b-0 { padding-bottom: 0 !important;}
.p-t-5 { padding-top: 5px !important;}
.p-b-5 { padding-bottom: 5px !important;}

.container-fluid {
    .row {
        *[class*=col-] {
            .card-wrape {
                margin-bottom: 0;
            }
        }
    }
}
.no-data-found {
    position: relative;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 200px;
    .no-data-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 200px;
        position: absolute;
        .no-data-text {
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
        }
    }
    .no-data-icon {
        background: url(../images/no-data-icon.svg) no-repeat 0 0;
        width: 60px;
        height: 70px;
        background-size: 100%;
    }
}
.no-record-pad {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    top: 50%;
    left: 50%;
    margin-left: -210px;
    margin-top: -115px;
    width: 420px;
    justify-content: center;
    span {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        color: $grey-black;
        font-weight: 500;
    }
    img {
        margin-bottom: 15px;
    }
}

button.back-to-top-btn {
    background: $septenary;
    width: 36px;
    height: 36px;
    cursor: pointer;
}
.stic-form-btn-wrape {
    position: fixed;
    bottom: 30px;
    right: 18px;
    padding: 12px 15px 12px 25px;
    border-radius: 33px 0 0 33px;
    box-shadow: 0 5px 20px rgba($color: #000000, $alpha: 0.15);
    background: $white;
    .mat-raised-button {
        cursor: pointer;
        width: 86px;
        + .mat-raised-button {
            margin-left: 10px;
        }
    }
}
.simplebar-track.simplebar-horizontal {
    display: none;
}
.input-wrape.title-controller {
    width: 75%;
    float: left;
    + button.add-btn {
        float: right;
        min-width: 80px;
    }
}
.icon-wrape-preview {
    width: 100%;
    height: 106px;
    position: relative;
    padding: 5px;
    background: #f0f0f0;
    margin-top: 5px;
    span.icon {
        position: absolute;
        width: 56px;
        height: 56px;
        left: 50%;
        margin-left: -27px;
        top: 32px;
        &.signature {
            background-position: -146px -99px;
        }
        &.photo {
            background-position: -277px -102px;
        }
        &.vdo {
            background-position: -213px -103px;
        }
        &.icf {
            background-position: -340px -102px;
        }
        &.pdf {
            background-position: -44px -182px;
            width: 39px;
	        height: 45px;
            margin-left: -18px;
            top: 23px;
        }
        &.img {
            background-position: -4px -182px;
            width: 34px;
	        height: 47px;
            margin-left: -17px;
            top: 23px;
        }
    }
    .upload-wrape {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        input#fileInput, button {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
.bottom-seprate-btn-wrape {
    width: 100%;
    display: flex;
    margin-top: 15px;
    margin-bottom: 10px;
    justify-content: flex-end;
    button {
        margin-left: 10px;
    }
}
.input-wrape {
    &.tree {
        .img-wrape {
            width: 106px;
            height: 106px;
            position: relative;
            padding: 5px;
            background-color: #f0f0f0;
            display: table;
            margin-bottom: 10px;
            a.delete-icon {
                bottom: 6px;
                right: 6px;
                opacity: 0;
                z-index: -2;
                transition: all 0.25s linear 0.25s;
            }
            &:hover {
                cursor: pointer;
                a.delete-icon {
                    z-index: 2;
                    opacity: 1;
                    transition: all 0.25s linear 0.25s;
                }
            }
        }
    }
}
.mat-autocomplete {
    + span.mat-form-field-label-wrapper {
        position: relative;
        &::after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid;
            margin: 0 4px;
            color: #162c55;
            position: absolute;
            right: 0px;
            top: 20px;
        }
    }
}
app-disease-select {
    .card-wrape.internal {
        position: absolute;
        z-index: 101;
        left: 50%;
        top: 30px;
        width: 600px;
        margin-left: -300px;
    }
}
.thumb-upload {
    &.icon-wrape-preview {
        max-width: 106px;
        border: 1px solid #405189;
        display: table;
        margin-bottom: 15px;
        + i.ruler {
            &:after {
                height: calc(100% - 79px);
            }
        }
        span.type {
            position: absolute;
            bottom: 6px;
            width: 100%;
            text-align: center;
            left: 0;
            color: #000;
        }
    }
}

.mat-autocomplete + span.mat-form-field-label-wrapper {
    position: absolute;
    &::after {
        top: 32px;
    }
}
.upload-thumb-wrape {
    &.full {
        .input-wrape {
            max-width: inherit;
            .icon-wrape-preview {
                max-width: 106px;
                border: 1px solid #405189;
            }
        }
    }
    .input-wrape {
        max-width: 126px;
        display: block;
        float: left;
        margin-bottom: 15px;
        padding-right: 20px;
        // min-height: 130px;
        //overflow: hidden;
        span.type {
            position: absolute;
            bottom: 6px;
            width: 100%;
            text-align: center;
            left: 0;
            color: #000;
        }
    }
}

.mat-expansion-panel-body {
    .head-controller-wrape {
        position: absolute;
        top: 0px;
        left: 0;
        right: 50px;
        display: flex;
        justify-content: flex-end;
        height: 55px;
        align-items: center;
        padding-right: 20px;
        a.indi-icon {
            margin-left: 10px;
        }
    }
}

.stat-wrape {
    width: 100%;
    display: table;
    border: 0 solid rgba(0,0,0,.125);
    justify-content: space-between;
    box-shadow: 0 1px 2px rgba(56,65,74,.15);
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    background: #fff;
    .stat-pad {
        border-right: 1px solid #ebedee;
        display: table-cell;
        padding: 1.5rem 1rem;
        vertical-align: middle;
        position: relative;
        &:last-child {
            border: 0;
        }
        p {
            font-size: 15px;
            color: #7e8297;
            padding-left: 85px;
            margin-bottom: 5px;
        }
        h2 {
            margin-bottom: 0;
            font-size: 28px;
            color: #333333;
            padding-left: 85px;
            line-height: 1.1;
            i {
                position: absolute;
                left: 20px;
                top: 20px;               
                width: 60px;
                height: 60px;
                background: #f7f7f7;
            }
        }
    }
    &.three {
        .stat-pad {
            width: 33.33%;
        }
    }
    &.four {
        .stat-pad {
            width: 25%;
        }
    }
    &.five {
        .stat-pad {
            width: 20%;
        }
    }
}
.mat-grid-tile-content {
    padding: 0 15px;
}
.justify-content-md-center {
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
}
.custom-btn{
    background: #162C55;
    margin: 0px 0px 0px 15px;
    padding: 0px 16px;
    margin-left: 10px !important;
    text-decoration: none !important;
    :hover {
        color: $seconadry;
    }
    .mat-button-wrapper{
        font-size: 15px !important;
        color:#FFFFFF;
    }
}
.card-wrape {
    background: #fff;
    width: 100%;
    border: 0 solid rgba(0,0,0,.125);
    float: left;
    box-shadow: 0 1px 2px rgba(56,65,74,.15);
    border-radius: .25rem;
    margin-bottom: 20px;
    padding: 0;
    .card-head {
        padding: 12px 15px;
        border-bottom: 1px solid rgba(0,0,0,.125);
        width: 100%;
        align-items: center;       
        .popup-close-btn {
            position: absolute;
            right: 15px;
            top: 20px;
            color: #ed1717;
            text-decoration: none !important;
            font-size: 22px;
            line-height: 22px;
        }
        &.with-dropdown {
            padding: 8px 15px;
        }
        @extend .display-flex;
        .left-text {
            font-weight: 600;
            color: #333;            
            font-size: 16px;
            margin-bottom: 0;
            margin: 4px 0;
            span.filter-text {
                border: 1px solid $primary;
                border-radius: 3px;
                color: $primary;
                padding: 3px 7px;
                margin-left: 5px;
                white-space: nowrap;
                font-weight: 400;
            }
        }
        .right-control {
            margin-left: auto;
            @extend .display-flex;
            .input-wrape {
                mat-form-field {
                    margin-bottom: 0;
                }
            }
            button.btn {
                padding: 0px 16px;
            }
            .mat-form-field-wrapper {
                padding-bottom: 0;
                margin-bottom: 0;
            }
            .mat-form-field-appearance-outline .mat-form-field-infix {
                padding: 0;
                width: auto;
            }
            .input-wrape + .input-wrape {
                margin-left: 5px;
            }
        }
    }
    .card-body {
        padding: 15px;
        width: 100%;
        float: left;
        position: relative;
        &.table-section {
            padding-bottom: 7px;
        }
    }
    .card-footer {
        padding: 15px;
        width: 100%;
        float: left;
        position: relative;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid rgba(0,0,0,.125);
        button {
            margin-left: 10px;
            min-width: 87px;
        }
    }
}
.sidebar-slider {
    float: left;
    width: 100%;
    height: calc(100vh - 78px);
}
ngx-simplebar {
    height: 200px
  }
#sidebar-wrape, .mat-drawer, .mat-tree {
    background: $tertiary;
}
.sidebar-slide-in + .right-layout {
    padding-left: 80px;
    transition: all 0.5s ease-in-out 0.5s;
    mat-toolbar {
        padding-left: 110px;
    }
    app-header {
        width: 100%;
        left: 0;
    }
}
.right-layout {
    padding-left: 300px;
    transition: all 0.5s ease-in-out 0.5s;
    padding-bottom: 60px;
}
div.mat-menu-panel {
    background: #fff;
    width: 180px;
    border: 1px solid #e4e4ee;
    box-shadow: none;
    border-radius: 0;
    margin-top: -2px;
    .mat-menu-content {
        padding: 0;
        a {
            color: #333;
            border-bottom: 1px solid #e4e4ee;
            position: relative;
            padding-left: 35px;
            font-weight: 500;
            &:last-child {
                border-bottom: 0;
            }
            &:hover {
                color: #9ccb3d;
                text-decoration: none;
            }
            &::before {
                @extend .sprite-icon;
                background-position: -2px -23px;
                position: absolute;
                left: 13px;
            }
            &.profile {
                &::before {
                    background-position: -2px -23px;
                    width: 15px;
	                height: 17px;
                    top: 15px;
                }
            }
            &.cms {
                &::before {
                    background-position: -140px -30px;
                    width: 16px;
	                height: 17px;
                    top: 15px;
                }
            }
            &.not-found {
                &::before {
                    background-position: -263px -22px;
                    width: 15px;
	                height: 18px;
                    top: 15px;
                }
            }
            &.logout {
                &::before {
                    background-position: -2px -41px;
                    width: 15px;
	                height: 17px;
                    top: 15px;
                }
            }
        }
    }
}
.example-tree-invisible {
    display: none;
}  
  .example-tree ul, .example-tree li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  .example-tree .mat-nested-tree-node div[role=group] {
    padding-left: 0px;
  }
  .example-tree div[role=group] > .mat-tree-node {
    padding-left: 0px;
  }
  .example-container {
    --visible-drawer-space: 70px;
  }
.mat-drawer {
    &.mat-drawer-side {
        transform: translate3d(calc(-100% + var(--visible-drawer-space)), 0, 0);
        visibility: visible !important; // always visible
    }
}
.mat-drawer-content {
    margin-left: var(--visible-drawer-space);
}
app-header {
    background: #999;
    width: calc(100% - 299px);
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 299px;
    z-index: 11;
}
app-main-container {
    display: block;
    width: 100%;
    padding: calc(70px + 1.5rem) 0.75rem 0 0.75rem;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background: #f3f3f9;
    margin-bottom: 0;
}
#sidebar-wrape, #sidebar-wrape .mat-tree {
    background: #405189;
}
#sidebar-wrape a, #sidebar-wrape .mat-tree a, #sidebar-wrape .mat-tree-node .mat-icon-button .mat-icon {
    color: #abb9e8;
}
/* ======== SIDE MENU STYLE END ======== */

/* ======== ADVANCE FILTER STYLE START ======== */
.filter-wrape {
    @media (max-width: 768px) {
        width: 315px;
    }
    position: fixed;
    right: 0;
    top: 64px;
    background: $white;
    height: calc(100vh - 50px);
    width: 370px;
    .filter-btn {
        background: $seconadry;
        padding: 13px 14px 12px;
        position: fixed;
        right: 0;
        top: 100px;
        border-radius: 4px 0 0 4px;
        display: flex;
        z-index: 99;
        box-shadow: 0 0 10px rgb(0, 0, 0, 0.15);
        i {
            width: 18px;
            height: 19px;
            display: inline-block;
            line-height: 1;
        }
    }
    .title {
        padding: 15px;
        border-bottom: 1px solid rgba(0,0,0,.125);
        color: $primary;
        h4 {
            margin-bottom: 0;
        }
        .close-btn {
            font-size: 30px;
            line-height: 16px;
            color: #ccc;
            position: absolute;
            right: 20px;
            top: 24px;
            text-align: center;
            background: none;
            cursor: pointer;
            padding: 0;
            &:hover {
                color: $seconadry;
            }
        }
    }
    .sidebar-content {
        padding: 15px;
        display: flex;
        height: calc(100vh - 190px);
        overflow: hidden;
        .sidebar-slider {
            height: 100%;
            overflow: auto;
        }
    }
    .footer-btn {
        display: flex;
        width: 100%;
        padding: 10px;
        justify-content: flex-end;
        button {
            &.btn {
                min-width: 86px;
            }
        }
    }
    .sidebar-slider {
        height: calc(100vh - 190px);
    }
    &.toggle-close {
        right: -400px;
        transition: all 0.25s linear 0.25s;
        .filter-btn {
            right: 0px;
            transition: all 0.25s linear 0.25s;
        }
        + .filter-overlay {
            display: none;
        }
    }
    &.toggle-open {
        right: 0;
        transition: all 0.25s linear 0.25s;
        box-shadow: 0 0 15px rgb(0, 0, 0, 0.15);
        z-index: 99;
        .filter-btn {
            right: 370px;
            transition: all 0.25s linear 0.25s;
            background: $seconadry;
        }
        + .filter-overlay {
            display: block;
        }
    }
}
/* ======== ADVANCE FILTER STYLE END ======== */

/* ======== SWEET ALERT STYLE START ======== */
.swal2-container {
    h2.swal2-title {
        font-size: 28px;
        padding-top: 0;
        line-height: 1.25;
    }
    .swal2-html-container {
        margin-top: 0;
    }
    .btn.mat-warn {
        color: $white;
    }
}
/* ======== SWEET ALERT STYLE END ======== */
.mat-ripple-element {
    z-index: 999;
}

.sidebar-slide-in .new-menu-wrapes a, .sidebar-slide-in .new-menu-wrapes .mat-list-item .mat-list-item-content {
    overflow: hidden;
    font-size: 0;
    .icons {
        position: relative;
        left: 0;
        display: inline-block;
    }
}

@import "media";

mat-radio-button.mat-accent.mat-radio-disabled {
    .mat-radio-outer-circle {
        border-color: rgba(44, 44, 44, 0.5);
    }
    .mat-radio-inner-circle {
        background: rgba(44, 44, 44, 0.5);
    }
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.pmr_pdf{
    min-width: 235px;
}

.popup_img{
    height: auto !important;
}

.grid_img{
    width:250px !important; 
    height:250px !important; 
}